.zoom-modal{
    min-width: 80vw;
    max-width: 80vw;

    height: 85vh;
    max-height: 85vh;

    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

.zoom-preview-cont{
    display: flex;
    justify-content: center;
    align-items: center;

    height: 70%;
    width: 100%;

    margin-bottom: 2vh;

    background-color: var(--B1TranspHalf);
}

.zoom-preview-img{
    max-width: 95%;

    max-height: 95%;

    
}

.zoom-img-input{
    width: 100%;

    display: flex;

    justify-content: space-between;
}

.zoom-img-input input[type="file"]{
    width: 80%;
    max-width: 80%;
    white-space: normal;
    word-wrap: break-word;

}

.zoom-modal-img{
    width: 48%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.zoom-modal-text{
    width: 48%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.zoom-modal-title{
    width: 100%;
    height: 5%;
    margin-bottom: 2vh;

    font-size: 1.6rem;
}

.zoom-modal-textarea{
    resize: none;
    width: 100%;
    height: 70%;

    margin-bottom: 2vh;

    padding-top: 5px;
}

.zoom-modal-button-double{
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.zoom-modal-button{
    border: solid 2px transparent;
}

.zoom-modal-button{
    cursor: pointer;

    padding: 1vh 2vh;
}

.zoom-modal-button:hover{
    color: white;
}

.zoom-modal-ok{
    border-color: var(--B3);
}
.zoom-modal-ok:hover{
    background-color: var(--B3);
}

.zoom-modal-cancel{
    color: var(--O1);
    border-color: var(--O1);
}
.zoom-modal-cancel:hover{
    background-color: var(--O1);
}

