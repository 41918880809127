@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

*{
    margin:0;
    padding:0;
    box-sizing: border-box;

    /* font-family: 'Verdana', sans-serif; */
    font-family: "Noto Sans", sans-serif;
}

:root{
    --border-tab-color: cornflowerblue;

    --background-color: #f0f0f0;

    --B1: #384d61;
    --B2: #476e94;
    --B3: #5c80a3;
    --B4: #85a6c7;
    --B5: #80cfe0;
    --B6: #85a7c7;
    --B6transp: #85a7c798;
    --O1: #e04714;
    --O2: #eb694d;
    --O3: #ffb58a;
    --O4: #f5b0a1;
    --O5: #b56961;
    --O6: #ffebb5;
    --O7: #f5f2e4;
    --B5GradientTop: #80cfe0;
    --B5GradientBot: rgba(128,207,224,0.6);
    --O3GradientTop: #ffb58a;
    --O3GradientBot: rgba(255,181,138,0.6);
    --B1Transp: rgba(56,77,97,0.9);
    --B1TranspHalf: rgba(56,77,97,0.5);
}

html{
    color: var(--B2);
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}

body, #root{
    height: 100%;
}

input[type="text"], input[type="number"], textarea{
    color: var(--B1);
    padding: 0px 5px;
}

textarea{
    padding-top: 5px;
}

/*********************LOGIN FORM**************************/
#login-main{
    height: 100vh;
    width: 100vw;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;

}

#login-form{
    margin-top: 5%;
    width: 250px;
    border: 2px solid var(--B6);
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    padding: 20px;
}

#login-form input,
#login-form label{
    display: block;
    margin-bottom: 10px;

}

#login-form button{
    width: 100%;
    margin-top: 20px;
    padding: 5px;
}

/*********************NAVIGATION****************************/

#nav-admin{
    height: 40px;
    display: flex;
    width: 60%;
    margin:auto;
    margin-top: 10px;
    justify-content: space-evenly;
    align-items: flex-end;

    border-bottom: 2px solid var(--B6);
    column-gap: 5px;
}

#nav-admin a{
    color: var(--B2);
    text-decoration: none;
    width: 150px;
    text-align: center;
    padding-bottom: 5px;
    padding-top: 5px;

    border: 2px solid var(--B6);
    border-bottom: none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

#nav-admin a:hover{
    color: white;
    background-color: var(--B6transp);
    text-decoration: none;
}

#nav-admin a.current{
    background-color: var(--B6);
    color: white;
}

#logout-button{
    cursor: pointer;
    display: block;
    margin-left: auto;
    height: 30px;
    width: 150px;
    background-color: var(--O1);
    color: white;

    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    border: 2px solid var(--O1);
    border-bottom: none;
}

#logout-button:hover{
    background-color: var(--O1);
    border-color: var(--O1);
}

.error-login-form{
    font-style: oblique;
    margin-bottom: 10px;
    color: var(--O1);
}

/***************** MODALS *****************/
.modal-cont {
    position: fixed;
    z-index: 2000;
    width: 100vw;
    height: 100vh;
    top: 0px;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgb(0, 0, 0, 0.8);

    backdrop-filter: blur(1px);
}
.main-modal{
    display: flex;
    /* flex-direction: column;
    align-items: center; */

    width: fit-content;
    padding: 2rem;
    background-color: white;
    border: 2px solid var(--B6);
}



/*************CSS GLOBAL****************/
main{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: calc(100vh - 40px);
}


/*****FORM ERROR***********/
@keyframes erreur{
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
}

.form-error{
    border:1px solid red !important;
    animation-name: erreur;
    animation-duration: 0.3s;
}