
#setlist-main{
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: calc(100vh - 80px);
}

#setlist-tab{
    width: 60%;
    height: 100%;
}
#setlist-tab .setlist-row{
    border-bottom: 2px solid var(--B6transp);
}

#setlist-tab .setlist-main-content .setlist-row:last-child{
    border-bottom: none;
}

#setlist-tab .header.setlist-row,
#setlist-tab .add-form.setlist-row{
    border-bottom: 2px solid var(--B6); 
}

#setlist-tab .add-form.setlist-row{
    border-top: 2px solid var(--B6); 
}

.setlist-main-content,
.add-form.setlist-row{
    border-left: 2px solid var(--B6);
    border-right: 2px solid var(--B6);
}

.header.setlist-row{
    height: 5%;
    font-size: 18px;
    font-style: oblique;
}

.header.setlist-row div:last-child{
    justify-self: center;
}

.setlist-main-content{
    height: 75%;
    max-height: 75%;
    overflow: auto;
    overflow-x: hidden;

    color: var(--B1);
}

.add-form{
    height: 10%;
}

.setlist-row{
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
}

.setlist-row > div{
    padding: 10px;
    display: flex;
    align-items: center;
}

.setlist-row .double-button{
    justify-content: space-evenly;
}

.double-button button{
    appearance: none;

    font-size: 1.5rem;

    background-color: var(--B2);
    background-clip: text;
    color: transparent;

    padding: 5px;

    border: none;
}

.double-button button:hover{
    background-color: var(--O1);
}


.add-form input{
    border:none;
    height: 100%;

    padding-left: 6px;
}

.add-form select{
    height: 100%;

}

.add-form input[type=number]::-webkit-inner-spin-button,
.add-form input[type=number]::-webkit-inner-spin-button{
    -webkit-appearance: none;
    margin: 0;  
}

.add-form input[type=number]{
    -moz-appearance: textfield;
    appearance: textfield; 
}

.add-form select{
    padding: 5px 0px;
    padding-left: 6px;
    border: none;
    background-color: white;
    display: block;
    width: 100%;
    margin: auto;
}
.add-form select:hover{
    background-color: lightgray;
}

.add-form button{
    display: block;
    padding: 5px;
    margin: auto;
}

.tab-func{
    cursor: pointer;
}

.sort-highlight{
    text-decoration: underline;
}

/* ANIMATION EFFECT */

@keyframes setlistLoad {
    0%{
        transform: translateY(200%);
        opacity: 0.1;
    }
    100%{
        transform: translateY(0%);
        opacity: 1;
    }
}

@keyframes setlistLoadInvert {
    0%{
        transform: translateY(-200%);
        opacity: 0.1;
    }
    100%{
        transform: translateY(0%);
        opacity: 1;
    }
}

.play-setlist-anim{
    animation-direction: normal;
    animation-fill-mode: both;
    animation-duration: 500ms;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
}

.asc-anim{
    animation-name: setlistLoad;
}

.desc-anim{
    animation-name: setlistLoadInvert;
}