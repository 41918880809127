.confirm-modal-cont{
    background-color: rgb(0,0,0,0.8);

    backdrop-filter: blur(1px);
}

.confirm-modal{
    flex-direction: column;
    align-items: center;

    width: 30vw !important;

    text-align: center;
}

.confirm-buttons{
    margin-top: 2rem;

    display: flex;
    width: 100%;

    flex-direction: row;

    justify-content: space-between;

    /* column-gap: 4rem; */
}

.confirm-button{
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    background-color: transparent;

    width: 150px;

    aspect-ratio: 2/1;

    border: 2px solid transparent;
}

.confirm-button:hover{
    color: white
}

.modal-confirm-btn{
    color: var(--B3);
    border-color: var(--B3);
}

.modal-cancel-btn{
    color: var(--O1);
    border-color: var(--O1);
}

.modal-confirm-btn:hover{
    background-color: var(--B3);
}

.modal-cancel-btn:hover{
    background-color: var(--O1);
}