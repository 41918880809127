#zoom-tab {
    position: relative;
    margin-top: 30px;
    width: 70%;
    height: 100%;
    max-height: calc(100% - 40px);

    color: var(--B1);
}

#zoom-tab>.zoom-row {
    padding-right: 16px;

}


.zoom-row {
    width: 100%;
    display: grid;
    grid-template-columns: 20% 20% 40% 20%;
    border-collapse: collapse;

    border-bottom: 2px solid var(--B6transp);

    /*padding-right: 20px;*/
}

.zoom-row.zoom-head{
    border-bottom: 2px solid var(--B2);
}

.zoom-row:last-of-type {
    border-bottom: none;
}

.zoom-row>div {
    border-collapse: collapse;
    display: flex;
    align-items: center;
}

.zoom-button {
    font-size: 35px;
    cursor: pointer;
    width: fit-content;
    margin: auto;


    background-color: var(--B2);
    background-clip: text;
    color: transparent;

    /* padding: 5px; */

    border: none;
}

.zoom-button:hover {
    background-color: var(--O1);
}

.zoom-img {
    padding: 10px;
    justify-content: center;
}

.zoom-img img {
    width: 80%;
}

.zoom-large-text,
.zoom-small-text {
    justify-content: center;
}

.zoom-head {
    height: 5%;
    font-size: 18px;
    font-style: oblique;
    color: var(--B2);
}

.zoom-head>div {
    justify-content: center;
}



.zoom-add-form {
    border-right: 2px solid var(--border-tab-color);
    border-left: 2px solid var(--border-tab-color);
}

.zoom-items-container {
    border-right: 2px solid var(--B2);
    border-left: 2px solid var(--B2);
    border-bottom: 2px solid var(--B2);
}



.zoom-items-container {
    position: relative;
    /* scroll-behavior: smooth; */
    width: 100%;
    height: fit-content;
    height: 85%;
    max-height: 85%;
    margin-bottom: 20px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.zoom-items-container .zoom-large-text,
.zoom-items-container .zoom-small-text,
.zoom-add-form .zoom-large-text,
.zoom-add-form .zoom-small-text {
    padding: 10px;
}

.zoom-items-container .zoom-button-double {
    justify-content: space-evenly;
}

.zoom-add-form {
    height: 15%;
}

.zoom-add-form input[type=text],
.zoom-add-form textarea {
    border: 1px solid lightcoral;
}

.zoom-edit-form input[type=text],
.zoom-add-form input[type=text] {
    display: block;
    width: 100%;
    /*border: none;*/
    padding: 10px;
    height: 100%;
}

.zoom-edit-form textarea,
.zoom-add-form textarea {
    height: fit-content;
    width: 100%;
    /*border: none;*/
    padding: 10px;
    resize: none;
}


.zoom-add-form .zoom-button-double {
    justify-content: center;
    align-items: center;
}

.zoom-add-form .zoom-button-double button {
    display: block;
    height: 60%;
    width: 75%;
}


/* BOUTTON NOUVEAU ZOOM */

.new-entry-btn-cont{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    height: fit-content;
}

.new-entry-button {
    align-self: center;
    position: relative;

    width: fit-content;
    height: fit-content;

    color: white;

    /* border: 2px solid var(--B6transp); */

    background-color: var(--B6);

    font-size: 30px;
    padding: 4px 8px;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

}

.new-entry-button:hover{
    /* border: 2px solid var(--B6transp); */

    background-color: var(--B2);

}

/* ANIMATION */

@keyframes zoomLoad {
    0%{
        transform: translateX(30%);
        opacity: 0.1;
    }
    100%{
        transform: translateY(0%);
        opacity: 1;
    }
}

.play-zoom-anim{
    animation-direction: normal;
    animation-fill-mode: both;
    animation-duration: 500ms;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;

    animation-name: zoomLoad;
}