
.sort-buttons{
    position: relative;
    margin-left: 1rem;

    flex-basis: 1;

    display: flex;

    user-select: none;
    
    align-items: center;

    column-gap: 0.3rem;

    height: 50%;
    width: 100%;
}

.sort-chevron{
    position: relative;
    cursor: pointer;
    height: 30px;
    width: auto;
    aspect-ratio: 1/1;

    background-color: var(--B4);
}

.chevron-active{
    background-color: var(--O1);
}

.sort-chevron:hover{
    background-color: var(--O1);
}

.chevron-desc{
    bottom: -4px;
    mask: url('../icons/chevron-down.svg') no-repeat center / contain;
}

.chevron-asc{
    top: -4px;
    mask: url('../icons/chevron-up.svg') no-repeat center / contain;
}