.block-main{
    margin-top: 30px;
    max-height: calc(100vh - 100px)
}

.block-list{
    width: 80%;
    display: flex;
    flex-wrap: wrap;

    justify-content: center;

    column-gap: 50px;
    row-gap: 20px;

    height: 100%;
    overflow: auto;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.block-list::-webkit-scrollbar{
    display: none;
}

.block-list iframe{
    width: 100%;
    height: calc(350px * 0.56);
}

.block-list > div{
    position: relative;

    width: 350px;
    display: flex;
    flex-direction: column;

    justify-content:space-between;

    height: 350px;

    border: 2px solid var(--B6);

    border-radius: 10px;

    overflow: hidden;
}

.block-add-form textarea,
.block-edit-form textarea{

    display: block;
    width: 100%;
    height: 60px;
    resize: none;
}

.block-add-item button{
    height: 50px;
}

.block-item-cont{
    height: calc(100% - 40px);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    padding: 10px 20px;
}

.block-button-cont{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 40px;

    border-bottom: 2px solid var(--B6);
}

.block-button-title{
    flex-grow: 1;
    text-align: center;
    font-weight: 700;
    font-size: 20px;
    padding-left: 40px;
    text-transform: uppercase;
}

.block-item,
.block-edit-form, 
.block-add-form{
    position: relative;
    overflow: hidden;

    background-color: white;
}

.block-edit-form input[type=text],
.block-add-form input[type=text],
.block-edit-form select,
.block-add-form select,
.block-edit-form textarea,
.block-add-form textarea{
    padding: 0px 5px;
}

.block-item img{
   max-height: 65%;
   max-width: 100%;
   width: auto;
   margin-left: auto;
   margin-right: auto;
   margin-bottom: 10px;
}

.block-item a{
    display: block;
}

.block-item h4{
    width: 100%;
    text-align: center;
    margin-bottom: 15px;
}

.block-item audio{
    display: block;
    width: 90%;
    border-radius: 20px;
    margin: 0px auto;
    background-color: var(--B6);
}

.block-button{
    background-color: var(--B6);
    background-clip: text;
    color: transparent;
    /* filter: grayscale(100%); */
    cursor: pointer;
    font-size: 25px
}

.block-button:hover{
    background-color: var(--O1);
}



.block-button-left,
.block-button-right{

    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 40px;
    border-left: 2px solid cornflowerblue;
}
.block-texte,
.block-item h4{
    width: 100%;
    padding: 0px 10px;
}

.block-legende,
.block-url{
    position: relative;

    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    word-break: break-all;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;

    max-height: 60px;
    padding: 0px ;

    margin: 10px 0px;

    width: 100%;


}

@keyframes blockLoad {
    0%{
        transform: translateY(40%);
    }
    100%{
        transform: translateY(0%);
    }
}

.block-list > div {
    animation-name: blockLoad;
    animation-iteration-count: 1;
    animation-duration: 500ms;
    animation-timing-function: ease-in-out;
    animation-fill-mode: both;
}

